<script>
export default {
  name: "IconBehance",
  props: {
    width: {
      type: [String, Number],
      default: "90",
    },
    height: {
      type: [String, Number],
      default: "90",
    },
  },
};
</script>

<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45 86.9499C68.2678 86.9499 87.13 68.0877 87.13 44.8199C87.13 21.5522 68.2678 2.68994 45 2.68994C21.7322 2.68994 2.87 21.5522 2.87 44.8199C2.87 68.0877 21.7322 86.9499 45 86.9499Z"
      stroke="#4F535C"
      stroke-width="4"
      stroke-miterlimit="10"
    />
    <path
      d="M61.68 36.69C52.45 36.45 48.74 43.58 48.74 49.29C48.74 55 51.89 61.39 58.95 61.98C66.01 62.57 70.87 57.06 70.57 55.2C70.34 53.79 67.63 53.37 65.85 54.58C64.66 55.38 63.04 56.67 60.3 56.75C56.02 56.88 54.46 53.35 55.27 52.09C55.87 51.15 68.71 51.6 70.32 50.75C71.94 49.9 71.53 36.95 61.68 36.69ZM64.91 46.65C63.24 48.39 54.66 48.04 55.29 46.05C55.29 46.05 55.67 42.16 60.5 42.05C65.08 41.95 66.01 45.5 64.91 46.65Z"
      fill="#4F535C"
    />
    <path
      d="M53.29 30.7299C52.73 31.2999 52.61 33.4499 53.8 33.8799C54.99 34.3099 66.06 33.9699 66.89 33.3299C67.85 32.5899 66.99 30.6499 66.31 30.3099C65.63 29.9699 54.71 29.3099 53.29 30.7299Z"
      fill="#4F535C"
    />
    <path
      d="M40.91 43.5801C40.91 43.5801 44.76 40.8201 43.63 35.6201C42 28.0701 29.97 28.6001 23.34 29.2101C21.37 29.3901 19.84 31.0101 19.78 32.9901C19.55 40.8101 19.09 59.2801 19.89 60.6901C20.91 62.4801 37.23 64.0101 42.93 59.7501C47.44 56.3901 48 45.4201 40.91 43.5801ZM26.53 35.2401C27.85 34.1301 34.11 34.4801 35.89 35.2401C37.98 36.1301 38.78 39.4101 36.87 41.2001C35.37 42.6001 28.32 42.4601 26.53 41.7001C25.64 41.3301 25.29 36.2801 26.53 35.2401ZM37.34 54.1801C35.84 55.5801 28.88 55.7701 27.08 55.0201C26.19 54.6401 25.88 49.1401 27.12 48.1001C28.44 46.9901 34.64 47.4801 36.48 48.1001C38.49 48.7801 39.25 52.3901 37.34 54.1801Z"
      fill="#4F535C"
    />
  </svg>
</template>
