<script>
export default {
  name: "IconLogo",
  props: {
    width: {
      type: [String, Number],
      default: "165",
    },
    height: {
      type: [String, Number],
      default: "126",
    },
    alt: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="box">
    <transition
      name="slide"
      mode="out-in"
    >
      <svg
        v-if="!alt"
        key="main"
        :width="width"
        :height="height"
        viewBox="0 0 165 126"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.2539 70.0842C29.2539 70.0842 31.4819 21.6877 31.9403 19.0222C32.862 13.6667 35.987 14.5273 36.9728 16.6764C38.8952 20.8615 35.7997 68.5744 34.2865 72.0906C32.7782 75.6069 29.1652 72.5726 29.2539 70.0842Z"
          fill="#4F535C"
        />
        <path
          d="M31.265 7.97181C31.1516 6.57515 33.0148 3.96377 35.4596 3.95394C38.9297 3.9441 39.6542 7.6374 38.3135 10.1504C36.9728 12.6634 31.6002 12.0733 31.265 7.97181Z"
          fill="#4F535C"
        />
        <path
          d="M66.6703 66.0663C64.8269 62.55 54.6089 48.6571 53.0859 46.6457C51.5677 44.6343 60.0654 44.0934 61.8004 35.0937C63.4763 26.3891 60.4597 21.5352 55.7574 18.855C51.06 16.1748 43.7749 18.8943 43.3411 22.5385C42.0842 33.0872 37.2833 69.7497 38.8853 71.9283C40.4872 74.1069 44.8494 74.3627 45.1846 71.1366C45.5198 67.9105 46.1901 54.352 46.8605 53.8503C47.5308 53.3487 59.4443 70.9054 62.4659 71.9087C65.4874 72.9119 68.5187 69.5825 66.6703 66.0663ZM47.7378 39.8001C46.5647 38.7968 47.2893 26.2219 48.7138 25.0466C50.8579 23.286 56.2651 26.5514 54.7568 33.0823C53.2485 39.6132 48.9159 40.8033 47.7378 39.8001Z"
          fill="#4F535C"
        />
        <path
          d="M65.3099 23.2171C65.3346 23.7187 65.8768 35.3986 67.5527 42.4606C69.5243 50.7472 72.876 54.5143 78.2487 56.6929C78.2487 56.6929 78.4163 64.227 77.5783 65.2302C76.7404 66.2335 73.2506 61.2124 71.555 62.8844C69.8594 64.5565 74.0541 71.7562 79.0866 72.425C84.1192 73.0939 83.4488 58.8666 84.2868 48.6522C85.1247 38.4378 85.5437 17.4288 80.7625 20.1926C77.9431 21.8205 80.9301 48.0571 77.5734 48.8194C73.64 49.7144 71.555 28.1153 69.8545 23.8761C67.8484 18.855 65.1818 20.4041 65.3099 23.2171Z"
          fill="#4F535C"
        />
        <path
          d="M91.0051 19.691C90.8572 21.5549 87.2787 67.6547 89.8319 71.1316C91.2219 73.0299 94.3617 71.9234 94.6969 69.5825C95.0321 67.2367 94.9483 45.7212 96.289 45.6671C97.6297 45.6179 102.697 69.5629 106.103 71.1316C107.779 71.9037 109.795 70.9202 110.13 68.0727C110.465 65.2253 113.654 23.3745 112.649 19.691C111.929 17.06 107.365 17.4583 107.281 22.5385C107.231 25.5678 105.605 49.8276 104.93 51.834C104.259 53.8454 97.1565 24.0433 96.0376 20.6943C94.692 16.6764 91.2614 16.4698 91.0051 19.691Z"
          fill="#4F535C"
        />
        <path
          d="M136.14 42.9426C136.269 30.1562 133.622 15.5059 127.081 15.9682C120.535 16.4354 117.351 26.217 115.67 36.7658C113.994 47.3145 115 69.912 115 69.912C114.699 72.9267 119.022 72.4988 119.865 71.8988C121.836 70.4972 120.698 54.7601 121.373 53.9241C122.048 53.0881 128.594 53.3389 129.6 53.6733C130.605 54.0077 129.935 64.5565 129.935 68.5744C129.935 72.5922 134.213 72.4496 135.386 71.1316C136.55 69.8235 136.022 54.8782 136.14 42.9426ZM129.762 47.3145C128.086 48.485 121.457 48.3227 121.373 46.4785C121.343 45.79 121.363 25.317 125.868 23.8073C130.373 22.2975 131.443 46.1441 129.762 47.3145Z"
          fill="#4F535C"
        />
        <path
          d="M4.42133 116.125C4.39669 103.737 10.8833 74.1463 12.224 66.6122C13.5647 59.078 16.2511 43.3803 19.8591 39.805C23.2158 36.4756 25.2269 42.2984 24.2213 52.1734C23.3538 60.696 18.3311 83.9721 17.0052 90.5129C15.4131 98.3814 12.7268 111.689 13.062 112.697C13.3971 113.705 27.2231 108.394 29.2539 113.115C30.2644 115.461 27.6618 121.569 18.1832 122.656C13.6288 123.172 4.43119 121.397 4.42133 116.125Z"
          fill="#4F535C"
        />
        <path
          d="M33.4189 116.543C33.3992 108.94 35.7159 90.8719 37.4854 87.8327C38.5107 86.0721 40.9998 85.4868 41.335 88.6687C41.6702 91.8505 39.5063 114.605 38.5698 117.212C37.3967 120.477 33.4239 119.533 33.4189 116.543Z"
          fill="#4F535C"
        />
        <path
          d="M36.1349 82.8066C35.297 80.869 37.0566 77.9527 39.6592 78.6215C42.4983 79.3543 42.6807 82.1378 40.9999 83.8098C39.3191 85.4868 36.8841 84.5426 36.1349 82.8066Z"
          fill="#4F535C"
        />
        <path
          d="M46.3676 92.3521C46.8654 88.4621 48.492 85.3049 54.5893 84.3016C60.6865 83.2984 66 86.8294 65.8324 88.5015C65.6648 90.1735 62.8947 89.5883 59.3704 89.0867C55.8462 88.5851 52.0705 88.9195 51.5332 92.5193C51.2079 94.6881 53.5344 96.2962 55.511 96.8716C58.7198 97.806 68.0111 97.3732 68.5138 104.406C69.0166 111.438 64.0432 115.869 55.4272 115.958C48.1766 116.032 44.6523 112.392 44.8544 108.256C45.1058 103.088 49.049 101.681 49.7194 103.211C50.3897 104.74 47.6886 107.361 50.0545 109.594C52.8986 112.274 60.9625 111.433 62.9736 108.925C64.9846 106.417 62.4708 104.194 58.2762 103.211C54.0865 102.227 45.3769 100.137 46.3676 92.3521Z"
          fill="#4F535C"
        />
        <path
          d="M87.6484 85.3983C83.4538 83.8049 78.5839 85.7376 75.0596 92.6029C71.5353 99.4683 71.5994 105.866 72.9648 110.278C73.6302 112.422 75.0793 115.441 79.7619 115.623C85.8887 115.859 89.832 107.342 91.3403 100.835C92.8486 94.3291 91.843 86.9917 87.6484 85.3983ZM86.2584 102.227C84.8635 107.391 80.5703 112.599 77.8297 108.591C76.5728 106.752 76.0454 101.126 78.4163 95.7012C80.4323 91.0981 83.5327 89.967 85.2973 91.1817C87.5745 92.7456 87.7322 96.788 86.2584 102.227Z"
          fill="#4F535C"
        />
        <path
          d="M128.515 84.9901C124.321 83.3968 119.451 85.3295 115.926 92.1948C112.402 99.0601 112.466 105.458 113.832 109.869C114.497 112.014 115.946 115.033 120.629 115.215C126.755 115.451 130.699 106.934 132.207 100.427C133.715 93.9209 132.71 86.5835 128.515 84.9901ZM127.125 101.819C125.73 106.983 121.437 112.191 118.696 108.183C117.44 106.343 116.912 100.717 119.283 95.293C121.299 90.6899 124.399 89.5588 126.164 90.7735C128.441 92.3374 128.599 96.3798 127.125 101.819Z"
          fill="#4F535C"
        />
        <path
          d="M94.1892 100.575C94.3125 97.2651 95.0272 89.4408 101.992 84.877C106.793 81.7296 112.042 84.4 112.042 85.9934C112.042 87.5867 106.27 86.5392 103.249 89.9719C99.4387 94.2996 97.8811 100.727 99.2218 107.258C100.563 113.789 105.684 110.022 106.354 108.345C107.024 106.673 104.175 104.489 102.322 101.814C100.513 99.2027 103.584 96.1241 107.443 95.6176C111.303 95.116 111.973 97.088 110.968 97.6486C109.962 98.2093 106.773 98.3765 106.438 99.8863C106.103 101.391 111.219 104.824 110.381 109.009C109.543 113.194 103.964 117.9 99.557 115.727C96.1214 114.03 93.8097 110.971 94.1892 100.575Z"
          fill="#4F535C"
        />
        <path
          d="M150.405 88.0835C150.405 88.0835 157.454 84.877 159.297 74.7758C161.412 63.2139 155.27 57.7846 150.908 56.9437C146.546 56.1076 141.094 56.565 138.994 65.063C136.673 74.4709 133.666 104.155 133.794 115.958C133.819 118.446 138.994 118.845 139.896 116.273C140.917 113.356 140.887 95.1946 142.903 94.0193C144.919 92.8439 151.391 110.356 153.929 115.289C156.699 120.669 160.924 119.656 161.407 117.148C162.417 111.881 150.405 88.0835 150.405 88.0835ZM142.435 83.0132C141.025 81.4739 143.785 64.345 145.841 62.9385C148.926 60.8189 155.605 66.0417 152.672 74.7807C149.745 83.5148 143.845 84.5524 142.435 83.0132Z"
          fill="#4F535C"
        />
      </svg>
      <svg
        v-else
        class="sun"
        key="alt"
        width="120"
        height="120"
        viewBox="0 0 138 138"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.7058 52.7369C31.7058 52.7369 -1.60218 61.4862 2.29711 76.5573C5.17332 87.6616 33.6748 80.5 33.6748 80.5C33.6748 80.5 11.5386 108.118 22.5464 117.973C33.5541 127.827 52.0758 103.167 52.0758 103.167C52.0758 103.167 52.4039 136.518 65.8777 133.56C79.3515 130.602 84.0904 99.1857 84.935 93.2403C85.2631 90.9287 107.949 125.236 120.912 114.339C131.466 105.469 111.878 76.0602 111.878 76.0602C111.878 76.0602 134.178 80.2828 135.862 69.6515C138 56.1826 106.005 50.7197 105.348 50.8838C104.692 51.0478 124.362 36.469 118.286 28.7476C112.206 21.0262 80.8282 32.8544 80.8282 32.8544C80.8282 32.8544 90.3544 7.72136 81.8126 3.45049C73.2709 -0.820391 60.8685 28.0913 60.8685 28.0913C60.8685 28.0913 54.952 2.98238 47.1486 5.25535C38.4476 7.78893 45.3437 33.6748 45.3437 33.6748C45.3437 33.6748 18.8932 21.6825 14.4583 29.8961C10.0233 38.1097 31.7058 52.7369 31.7058 52.7369Z"
          fill="#F4A706"
        />
        <path
          d="M48.3695 66.8622C47.0183 65.0042 44.0986 57.8957 47.4236 57.8282C51.4484 57.7461 53.3884 70.1293 65.3324 69.4923C79.9113 68.7105 82.0877 54.2136 84.8819 53.3932C87.6761 52.5728 88.5881 56.9499 84.8819 64.0728C81.7596 70.0714 75.2302 75.8624 65.4965 75.8624C55.6372 75.8576 51.7138 71.4612 48.3695 66.8622Z"
          fill="white"
        />
        <path
          d="M77.1605 43.0417C75.6838 44.6825 75.7948 48.133 77.9809 49.2864C81.7547 51.2698 84.3897 47.3126 83.4004 44.3592C82.4111 41.4058 78.7868 41.2369 77.1605 43.0417Z"
          fill="white"
        />
        <path
          d="M48.6204 45.5994C47.1437 47.2402 47.2547 50.6907 49.4408 51.8441C53.2146 53.8275 55.8495 49.8703 54.8602 46.9169C53.8806 43.9587 50.2467 43.7946 48.6204 45.5994Z"
          fill="white"
        />
      </svg>
    </transition>
  </div>
</template>

<style scoped>
.box {
  width: 145px;
  height: 126px;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.1s, transform 0.1s;
  transition-delay: 0.1s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-10%);
}</style>
